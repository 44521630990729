import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { css, keyframes } from '@emotion/react';

import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import SEO from '../../components/seo';
import TrailTitle from '../../components/TrailTitle';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

const rightBottomGreyRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(233, 233, 233, 1) 35%,
    rgba(233, 233, 233, 1) 65%,
    rgba(0, 0, 0, 0) 66%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat  : no-repeat;
  background-position: calc(100% + 200px) calc(100% + 200px);
  background-size    : 400px 400px;
`;

const delay = keyframes`
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 0;
    height : initial;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DelayFade = styled(Col)`
  animation: ${delay} ${(props) => props.order * 0.4}s,
    ${fade} 0.3s ${(props) => props.order * 0.4}s;
`;

const FacilitiesPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  return (
    <>
      <SEO title='Fasilitas dan Teknologi' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <Row
        css={{
          minHeight         : '75vh',
          backgroundImage   : `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat  : 'no-repeat',
          backgroundPosition: 'top right',
        }}
        className='pt-5'>
        <Col
          xs        = {'12'}
          sm        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'>
          <TrailTitle open={open} className='pb-2 mb-5'>
            Fasilitas
          </TrailTitle>
        </Col>
        <Col xs={'12'} sm={{ span: '8', offset: 2 }}>
          <Row className='bg-secondary mb-5 p-3'>
            {data.facilities.edges.map(
              ({ node: { name, logo, cockpitId } }, idx) => (
                <DelayFade
                  sm        = {'4'}
                  key       = {cockpitId}
                  className = 'mb-4'
                  order     = {idx}>
                  <Row className='justify-content-center align-items-center align-content-center'>
                    <Col
                      xs        = {'3'}
                      className = 'd-flex justify-content-center align-items-center align-content-center'>
                      <Img fixed={logo.value.childImageSharp.fixed} />
                    </Col>
                    <Col xs={'6'}>
                      <h5 className='mb-0'>{name.value}</h5>
                    </Col>
                  </Row>
                </DelayFade>
              )
            )}
          </Row>
          <h2>Teknologi RSGM IIK BW</h2>
          {data.technologies.edges.map(
            ({ node: { name, description, photo, cockpitId } }, idx) => (
              <Row
                key       = {cockpitId}
                className = {`p-3 p-md-5 ${idx % 2 === 0 ? '' : 'bg-secondary'}`}
                css       = {idx % 2 === 0 ? rightBottomGreyRing : null}>
                <Col
                  sm        = {{ span: '5', order: idx % 2 === 0 ? '1' : '2' }}
                  className = 'd-flex flex-column align-items-center align-content-center'>
                  <Img
                    fixed     = {photo.value.childImageSharp.fixed}
                    className = 'mb-2'
                  />
                </Col>
                <Col sm={{ span: '7', order: idx % 2 === 0 ? '2' : '1' }}>
                  <h4 className='font-weight-bold'>{name.value}</h4>
                  <ReactMarkdown
                    source     = {description.value}
                    escapeHtml = {false}
                  />
                </Col>
              </Row>
            )
          )}
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    facilities: allCockpitFacilities(
      filter: { display: { value: { eq: true } } }
      sort  : { fields: cockpitCreated, order: ASC }
    ) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          logo {
            value {
              childImageSharp {
                fixed(width: 48, toFormat: WEBP) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    technologies: allCockpitTechnologies(
      filter: { display: { value: { eq: true } } }
    ) {
      edges {
        node {
          name {
            value
          }
          description {
            type
            value
          }
          photo {
            value {
              childImageSharp {
                fixed(width: 300, toFormat: WEBP) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default FacilitiesPage;
